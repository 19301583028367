import { tokenwhatsapp, urlwhatsapp } from "./config";

export function sendMessage(phoneNumber, code) {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenwhatsapp}`,
    },
    body: JSON.stringify({
      messaging_product: "whatsapp",
      to: phoneNumber,
      type: "template",
      // template: { name: "hello_world", language: { code: "en_US" } },
      template: {
        name: "enlace_inspeccion",
        language: { code: "es" },
        components: [
          {
            type: "button",
            sub_type: "url",
            index: 0,
            parameters: [
              {
                type: "text",
                text: code,
              },
            ],
          },
        ],
      },
    }),
  };

  return fetch(urlwhatsapp, params)
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .then((result) => {
      if (result.user) {
        return {
          status: true,
          message: "MEnsaje enviado correctamente",
        };
      }
      return {
        status: false,
        message: result.message,
      };
    })
    .catch((error) => {
      return {
        status: false,
        message: `Error de servidor, ${error.message}`,
      };
    });
}
