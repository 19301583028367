import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { useNavigate } from "react-router-dom";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import VideoCameraOutlined from "@ant-design/icons/VideoCameraOutlined";
import PushpinOutlined from "@ant-design/icons/PushpinOutlined";

import "./Eventos.scss";
import { getEventsApi } from "../../../api/event.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditEventForm from "../../../components/Admin/Event/EditEventForm";
import moment from "moment";
import { durationInspection } from "../../../utils/functions";

export default function Event() {
  const [event, setEvent] = useState([]);
  const token = getAccessTokenApi();
  const [reloadEvents, setReloadEvents] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();

  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const url = window.location.origin;

  useEffect(() => {
    getEventsApi(token, true).then((response) => {
      setEvent(response.events);
      setLoadingData(false);
    });

    setReloadEvents(false);
  }, [token, reloadEvents]);

  const addEvent = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva conferencia");
    setmodalContent(
      <EditEventForm
        event={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Agregar conferencia"
        addSlag={"add"}
      />
    );
  };

  const editEvent = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar conferencia");
    setmodalContent(
      <EditEventForm
        event={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Editar conferencia"
        addSlag={"edit"}
      />
    );
  };

  const deleteEvent = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar conferencia");
    setmodalContent(
      <EditEventForm
        event={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Eliminar conferencia"
        addSlag={"delete"}
      />
    );
  };

  const navigateGoogle = (record) => {
    //location.href = `https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lon}`;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lon}`,
      "_blank",
      "noreferrer"
    );
  };

  const columns = [
    {
      title: "Expediente",
      width: 50,
      dataIndex: "record",
      key: "record",
      fixed: "left",
    },
    // {
    //   title: "País",
    //   width: 50,
    //   dataIndex: ["country", "name"],
    //   key: "countryId",
    // },
    // {
    //   title: "Código",
    //   width: 50,
    //   dataIndex: ["country", "code"],
    //   key: "countryId",
    // },
    {
      title: "Número móvil",
      width: 50,
      dataIndex: "mobil",
      key: "mobil",
    },
    {
      title: "Correo electrónico",
      width: 80,
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "Fecha creación",
      width: 60,
      dataIndex: "createdAt",
      key: "createdAt",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    },
    {
      title: "Url del cliente",
      width: 140,
      dataIndex: "code",
      key: "code",

      render: (text) => (
        <a target="_blank" href={url + "/conferencia/" + text} rel="noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Nombre inspector",
      width: 60,
      dataIndex: ["user", "name"],
      key: "userId",
    },
    {
      title: "Apellido inspector",
      width: 60,
      dataIndex: ["user", "lastname"],
      key: "userId",
    },

    {
      title: "Longitud",
      width: 50,
      dataIndex: "lat",
      key: "lat",
    },
    {
      title: "Latitud",
      width: 50,
      dataIndex: "lon",
      key: "lon",
    },
    {
      title: "Tiempo de inspección",
      width: 50,
      dataIndex: "start",
      key: "start",

      render: (text, record) => (
        <span> {durationInspection(record.start, record.end)}</span>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => navigateGoogle(record)}
          >
            <PushpinOutlined />
          </Button>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editEvent(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            className="margin-right-minimun"
            onClick={() => deleteEvent(record)}
          >
            <DeleteOutlined />
          </Button>
          <Button
            size="small"
            type="default"
            onClick={() => navigate(`/admin/conferencia/${record.code}`)}
          >
            <VideoCameraOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="event">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Eventos</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addEvent}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={event}
        loading={loadingData}
        rowKey="id"
        scroll={{ x: 2100 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen conferencias"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
