//export const basePath = "https://api-ci.aplios.software";

//prod
export const basePath = "https://inspectorapi.saferisk.ec";
//dev
//export const basePath = "http://localhost:3981";

export const apiVersion = "v1";

export const tokenwhatsapp =
  "EAAMXqL3kmNoBOwFZBx1OvPMH2HDD38OJ62ztXbZAYDqeR86UfkWPjNSpFNNw8wIZBM0dAXENZCKQfjZB8EkhRRdbKPpZB7JevghmXPDj2dGh99LOOPobTWwC0CZAjvZAAUA6RsvP7WKbv4PumqYuXq7LNmQOg0ktvpWkZBw2ROQn063a6NOZA9thWTdUIKOSIZBkesOb6Mi3ZAZCDpg0UkFgvT4eKOqRCqvqR";

export const urlwhatsapp =
  "https://graph.facebook.com/v20.0/389359370933774/messages";
