import moment from "moment";

export function formatDate(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("YYYY/MM/DD");
}

export function formatYear(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("YYYY");
}

export function formatMonth(dateFull) {
  let dateFormat = moment(dateFull);
  return dateFormat.format("MMM");
}

export function formatMonthYear(dateFull) {
  let dateFormat = moment(dateFull).add(1, "day");
  return dateFormat.format("YYYY/MMM");
}

export function durationInspection(start, end) {
  var seconds = moment.duration(moment(end).diff(start)).seconds();
  var minutes = moment.duration(moment(end).diff(start)).minutes();
  var hours = Math.trunc(moment.duration(moment(end).diff(start)).asHours());

  if (hours > 0) {
    if (minutes > 0) {
      if (seconds > 0) {
        return (
          hours + " horas, " + minutes + " minutos, " + seconds + " segundos"
        );
      } else {
        return "";
      }
    } else {
      if (seconds > 0) {
        return (
          hours + " horas, " + minutes + " minutos, " + seconds + " segundos"
        );
      } else {
        return "";
      }
    }
  } else {
    if (minutes > 0) {
      if (seconds > 0) {
        return minutes + " minutos, " + seconds + " segundos";
      } else {
        return "";
      }
    } else {
      if (seconds > 0) {
        return seconds + " segundos";
      } else {
        return "";
      }
    }
  }
}

export function returnCoordinates(polyString) {
  let geojsonFeature = JSON.parse(polyString);
  return geojsonFeature;
}

export function returnTruncate(str, length) {
  return str.length > length ? str.substring(0, length - 3) + "..." : str;
}

export function returnMailImage(porcentage) {
  if (porcentage === 0) {
    return "0";
  } else if (porcentage > 0 && porcentage <= 10) {
    return "10";
  } else if (porcentage > 10 && porcentage <= 20) {
    return "20";
  } else if (porcentage > 20 && porcentage <= 30) {
    return "30";
  } else if (porcentage > 30 && porcentage <= 40) {
    return "40";
  } else if (porcentage > 40 && porcentage <= 50) {
    return "50";
  } else if (porcentage > 50 && porcentage <= 60) {
    return "60";
  } else if (porcentage > 60 && porcentage <= 70) {
    return "70";
  } else if (porcentage > 70 && porcentage <= 80) {
    return "80";
  } else if (porcentage > 80 && porcentage <= 90) {
    return "90";
  } else {
    return "100";
  }
}
