import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, InputNumber } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateEventApi,
  AddEventApi,
  DeleteEventApi,
} from "../../../../api/event.api";
import { sendMessage } from "../../../../api/whatsapp.api";
//import useAuth from "../../../../../hooks/useAuth";
import { v4 as uuidv4 } from "uuid";
import "./EditEventForm.scss";
import SelectCountryForm from "../../../../components/Admin/Country/SelectCountryForm";
//import moment from "moment";
import useAuth from "../../../../hooks/useAuth";

export default function EditEventForm(props) {
  const { event, setIsVisibleModal, setReloadEvents, buttonName, addSlag } =
    props;
  const [eventData, setEventData] = useState({});
  const [country, setCountry] = useState();
  const [code, setCode] = useState("+0");
  const [codeuuid, setCodeuuid] = useState();
  const [loading, setLoading] = useState(false);
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [formEvent] = Form.useForm();
  const { user } = useAuth();
  const url = window.location.origin;
  // const pathname = window.location.pathname;
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;

  useEffect(() => {
    if (event) {
      formEvent.setFieldsValue(event);
    } else {
      var uuidtemp = uuidv4();
      setCodeuuid(uuidtemp);
      formEvent.setFieldsValue({
        record: null,
        mobil: null,
        mail: null,
        code: uuidtemp,
        url: null,
        countryId: null,
        userId: user.id,
      });
    }

    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [event]);

  useEffect(() => {
    //setLoading(true);
    if (addSlag === "add") {
      var uuidtemp = uuidv4();
      setCodeuuid(uuidtemp);
      formEvent.setFieldsValue({
        record: null,
        mobil: null,
        mail: null,
        code: uuidtemp,
        url: null,
        countryId: null,
        userId: user.id,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddEventApi(token, {
        url: url + "/conferencia/" + values.code,
        userId: user.id,
        ...values,
      }).then((result) => {
        if (result.status) {
          sendMessage(`${code.replace("+", "")}${values.mobil}`, values.code)
            .then((result) => {
              console.log(result);
            })
            .catch((err) => {
              return err.message;
            });
          notification["success"]({
            message: result.message,
          });
          setReloadEvents(true);
          setIsVisibleModal(false);
          setLoading(false);
          var uuidtemp = uuidv4();
          setCodeuuid(uuidtemp);
          formEvent.setFieldsValue({
            record: null,
            mobil: null,
            mail: null,
            code: uuidtemp,
            url: null,
            countryId: null,
            userId: user.id,
          });
        } else {
          notification["error"]({
            message: result.message,
          });
        }

        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateEventApi(token, { ...values }, event.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEvents(true);
        setIsVisibleModal(false);
        setLoading(false);
        setEventData({
          ...eventData,
        });
      });
    } else {
      DeleteEventApi(token, event.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEvents(true);
        setIsVisibleModal(false);
        setLoading(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  const onlynumbers = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
  };

  return (
    <div className="edit-event-form">
      <Form
        form={formEvent}
        name="event"
        className="form-edit"
        //onSubmit={addUpdateEvent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Expediente"
              description="Número de referencia para relacionar la información del cliente"
              name={"record"}
              rules={[
                {
                  required: true,
                  message: "Ingrese el nombre del conferencia",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Número de referencia para relacionar la información del cliente"
                maxLength={300}
                //value={userData.name}
                onChange={
                  () => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <SelectCountryForm
              atributoData={country}
              setAtributoData={setCountry}
              setCode={setCode}
            ></SelectCountryForm>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="mobil"
              label="Número móvil"
              rules={[{ required: true, message: "Ingresa el número móvil" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              onChange={onlynumbers}
            >
              <InputNumber
                maxLength={9}
                addonBefore={code}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Correo electrónico"
              name="mail"
              rules={[
                { required: true, message: "Ingrese el correo electrónico" },
                {
                  type: "email",
                  message: "El correo ingresado no es correcto",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Correo electrónico"
                //value={userData.name}
                //disabled
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Url para el conferencia"
              name={"code"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la url para el conferencia",
                },
                {
                  pattern: /^[a-z0-9-]*$/,
                  message: "El Url tiene un formato incorrecto",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              initialValue={codeuuid}
              disabled
            >
              <Input
                addonBefore={url + "/conferencia/"}
                //prefix={<UserOutlined />}
                placeholder="Url para el conferencia"
                maxLength={300}
                onChange={
                  () => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
                value={codeuuid}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
            loading={loading}
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
